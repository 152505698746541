import React from "react"
import {Bar, Line} from "react-chartjs-2"
//import 'chartjs-plugin-datalabels'

class Cancellation extends React.Component {
  render () {
    const cancellations = this.props.cancellations
    const first_outcomes = this.props.first_outcomes
    var months = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
    var cancellation_counts = Array(12).fill(null)
    var first_outcome_counts = Array(12).fill(null)
    var datasets = []
    var i = 0
    for (i; i < cancellations.length; i++) {
      const year = cancellations[i][0][0]
      const month = cancellations[i][0][1]
      const nums = cancellations[i][1]
      cancellation_counts.splice(month-1, 1, nums)
    }
    const cancellation_dataset = {
        type:'line',
        data: cancellation_counts,
        fill: false,
        borderColor: '#5eaaa8',
        label: '解約数',
        backgroundColor: '#5eaaa8', // <--追加
    }
    datasets.push(cancellation_dataset)

    i = 0
    for (i; i < first_outcomes.length; i++) {
      const year = first_outcomes[i][0][0]
      const month = first_outcomes[i][0][1]
      const nums = first_outcomes[i][1]
      first_outcome_counts.splice(month-1, 1, nums)
    }
    const first_outcome_dataset = {
        type:'line',
        data: first_outcome_counts,
        borderColor: '#f05945',
        fill: false,
        label: '初回成果数',
        backgroundColor: '#f05945', // <--追加
    }
    datasets.push(first_outcome_dataset)
    const data = {
    labels: months,
    datasets: datasets,
    };
    const options = {
    maintainAspectRatio: false,
    responsive: false,
    legend: { display: true, position: "bottom" },
    title: {
        display: true,
        text: '解約数の推移と初回成果の推移',
        fontSize: 30
    },
    }
    return (
    <React.Fragment>
        <Line data={data} options={options} width={1000} height={500}/> 
    </React.Fragment>
    );
}
}


export default Cancellation;