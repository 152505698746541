import React from "react"
import {Bar} from "react-chartjs-2"
//import 'chartjs-plugin-datalabels'

class StackBar extends React.Component {
  render () {
    const  labels = this.props.label;
    const lenData = this.props.data.length;
    function setStack (index){
      if( lenData == index){
        return 2
      }else{
        return 1
      }
    }
    const data = this.props.data.map((x, index)=>(
      {
        label: this.props.title[index],
        fill: true,
        lineTension: 0.1,
        backgroundColor: this.props.backgroundColor[index],
        borderColor: this.props.borderColor[index],
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: x,
        stack: setStack(index+1)
      })

    );
    //const data = this.props.data.map((x)=>  x.total_view_num); 
    const graph = {
      labels: labels,
      datasets: data
    };
    const options = {
      plugins: {
        datalabels: {
          display: true,
          color: 'black'
        }},
      scales: {
          yAxes: [{
              stacked: true
          }]
      },
      maintainAspectRatio: false,
      responsive: false,
    };

    return (
      <React.Fragment>
        <Bar data={graph} options={options} width={600} height={300}/>
      </React.Fragment>
    );
  }
}

export default StackBar
