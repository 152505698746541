import React from "react"
import {Bar, Line, LineChart} from "react-chartjs-2"
//import 'chartjs-plugin-datalabels'

class DailyBenefitAccumulation extends React.Component {
    render () {
      const sums = this.props.sums
      var target_dates = []
      var accumlation = []
      var i = 0
      var sum = 0
      console.log(sums)
      for (i;  i < sums.length; i++) {
        target_dates.push(sums[i][0])
        sum += sums[i][1]
        accumlation.push(sum)
      }

      const dataset = {
        type:'line',
        data: accumlation,
        label: '累積推移',
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      }
      const data = {
        labels: target_dates,
        datasets: [dataset],
      };
      const options = {
      maintainAspectRatio: false,
      responsive: false,
      legend: { display: true, position: "bottom" },
      title: {
          display: true,
          text: '今月売上の累積推移',
          fontSize: 30
      },
    }
    return (
      <React.Fragment>
        <Line data={data} options={options} width={1000} height={500}/> 
      </React.Fragment>
    );
  }
}

export default DailyBenefitAccumulation;