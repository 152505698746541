import React from "react"
import {Bar, Line, LineChart} from "react-chartjs-2"

export default function AccumulativeContract(props) {
    var months = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
    var accumulative_active_contract_num = 0
    var accumulative_contract_num = 0
    var bar_data = []
    var line_data = []
    for (var contract of props.monthly_active_contracts) {
        accumulative_active_contract_num += contract.count
        bar_data.push(accumulative_active_contract_num)
    }
    for (var contract of props.monthly_contracts) {
        accumulative_contract_num += contract.count
        line_data.push(accumulative_contract_num)
    }
    const bar_dataset = {
        type: 'bar',
        backgroundColor: '#50CB93',
        label: '累計契約数（契約、契約変更のみ）',
        data: bar_data
        
    }
    const line_dataset = {
        type: 'line',
        borderColor: '#54436B',
        label: '累計契約数',
        fill: false,
        data: line_data
    }
    const data = {
        labels: months,
        datasets: [bar_dataset, line_dataset],
      };
    const options = {
        maintainAspectRatio: false,
        responsive: false,
        legend: { display: true, position: "bottom" },
        plugins: {
          datalabels: {
            display: false
          }
        },
        title: {
          display: true,
          text: '契約数の累計',
          fontSize: 30
        },
      }
    return <Bar data={data} options={options} width={1000} height={500}/>
}