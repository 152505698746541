import React from "react"
import {Bar, Line, LineChart} from "react-chartjs-2"

export default function DailyCancellation(props) {
    console.log(props)
    const months_days = {1:31, 2:28, 3:31, 4:30, 5:31, 6:30, 7:31, 8:31, 9:30, 10:31, 11:30, 12:31}
    var today = new Date();
    var cancellations = new Array(months_days[today.getMonth() + 1]).fill(null)
    var accumulative_cancellation = 0
    var accumulative_first_outcome = 0
    var first_outcomes = new Array(months_days[today.getMonth() + 1]).fill(null)
    console.log(today.getMonth())
    var dates = cancellations.map((_, index) => `${today.getMonth() + 1}/${index+1}`)
    for (var cancellation of props.cancellations) {
        console.log(cancellation.cancellation_date)
        var [year, month, date] = cancellation.cancellation_date.split('-')
        var cancellation_date = new Date(year, month, date)
        console.log(cancellation_date)
        var index = cancellation_date.getDate() - 1
        accumulative_cancellation += cancellation.count
        cancellations.splice(index, 1, accumulative_cancellation)
    }
    for (var outcome of props.first_outcomes) {
        var [year, month, date] = outcome.outcome_start.split('-')
        var first_outcome_date = new Date(year, month, date)
        var index = first_outcome_date.getDate() - 1
        accumulative_first_outcome += outcome.count
        first_outcomes.splice(index, 1, accumulative_first_outcome)
    }
    console.log(first_outcomes)

    const cancellation_dataset = {
        type:'line',
        data: cancellations,
        label: '解約',
        borderColor: '#524C84',
        fill: true
    }
    const first_outcome_dataset = {
    type:'line',
    data: first_outcomes,
    label: '初回成果',
    borderColor: '#D65D7A',
    fill: true
    }

    const datasets = [cancellation_dataset, first_outcome_dataset]
    const data = {
        labels: dates,
        datasets: datasets,
      };
      const options = {
        maintainAspectRatio: false,
        responsive: false,
        legend: { display: true, position: "bottom" },
        plugins: {
          datalabels: {
            display: false
          }
        },
        title: {
          display: true,
          text: '今月の解約と初回成果の累積推移',
          fontSize: 30
        },
        scales: {
          yAxes: [{
              ticks: {
                  beginAtZero: true
              }
          }]
      }
      }


    return <Line data={data} options={options} width={1000} height={500}/> 
}