import React from "react"
import {Bar, Line, LineChart} from "react-chartjs-2"
//import 'chartjs-plugin-datalabels'

class DailyBenefit extends React.Component {

  render () {
    var datasets = []
    //length = 32
    var count = 0
    const colors = [
      '#d8b3ee', '#434d4b', '#301893', '#3cfdbb', '#10403f', '#8d0ab8', '#866ae3', '#6d7bfa', '#bc693f', '#d8c201',
      '#d8c201', '#79e23b', '#c7049c', '#631fff', '#801263', '#bd97a5', '#aed376', '#cb3f54', '#9c006a', '#ec337e',
      '#ec852c', '#1395a4', '#8d413c', '#ccf98c', '#3babc4', '#d1a87d', '#ec2d49', '#2e758b', '#74841c', '#6ece67',
      '#266c17', '#ae593f']
    colors.reverse()
    const today = new Date()
    const days_passed = today.getDate()
    //daily_contracts = [['id', 'daily_contracts.first_name', 'daily_contracts.last_name' 'target_date', 'contract_accumulation'], ....]
    const daily_contracts = this.props.daily_contracts
    var i = 0
    var contract_dates = Array(days_passed).fill(0)
    var contract_dates = contract_dates.map((_, index) => {return `${today.getFullYear()}/${today.getMonth() + 1}/${index + 1}`})
    var contract_accumulation = Array(days_passed).fill(0)
    var sums = Array(days_passed).fill(0)
    for (i; i < daily_contracts.length; i++) {
      const the_day = new Date(daily_contracts[i].contract_start_date)
      const index = the_day.getDay() -1
      contract_accumulation.splice(index, 0, daily_contracts[i].count)
      if (i == daily_contracts.length - 1 || daily_contracts[i].id !== daily_contracts[i+1].id) {

        var j = 0;
        for (j; j < contract_accumulation.length; j++) {
          if (j !== 0) {
            contract_accumulation[j] += contract_accumulation[j-1]
          }

          sums[j] += contract_accumulation[j]
        }

        var dataset = {
          type:'line',
          data: contract_accumulation,
          label: daily_contracts[i].last_name + ' ' + daily_contracts[i].first_name,
          borderColor: colors[count],
          fill: true
        }
        count += 1
        contract_accumulation = Array(days_passed).fill(0)
        datasets.push(dataset)
      }
    }
    const sum_dataset = {
      type:'line',
      data: sums,
      label: '合計',
      borderColor: 'black',
      fill: true
    }
    datasets.push(sum_dataset)
    const data = {
      labels: contract_dates,
      datasets: datasets,
    };
    const options = {
      maintainAspectRatio: false,
      responsive: false,
      legend: { display: true, position: "bottom" },
      plugins: {
        datalabels: {
          display: false
        }
      },
      title: {
        display: true,
        text: '今月の契約数の累積推移',
        fontSize: 30
      },
    }
    return (
      <React.Fragment>
        <Line data={data} options={options} width={1000} height={500}/> 
      </React.Fragment>
    );
  }
}

export default DailyBenefit;
