import React from "react"
import {Bar, Line, LineChart} from "react-chartjs-2"

export default function DailyAverageProfit(props) {
    var target_dates = []
    var average_profits = []
    for (var profit of props.daily_benefits) {
        const  [year, month, date] = profit.target_date.split('-')
        const estimated_upper_profit = profit.today_upper_sales * parseInt(date)
        const accumulated_profit = profit.nomal_sales + estimated_upper_profit + profit.fixed_sales
        const average_profit =  accumulated_profit / parseInt(date)
        target_dates.push(profit.target_date)
        average_profits.push(average_profit)
    }

    const average_profit_dataset = {
        type:'line',
        data: average_profits,
        label: '日当たり平均の日次推移',
        borderColor: '#F7E6AD',
        fill: false
    }

    const data = {
        labels: target_dates,
        datasets: [average_profit_dataset],
      };
      const options = {
        maintainAspectRatio: false,
        responsive: false,
        legend: { display: true, position: "bottom" },
        plugins: {
          datalabels: {
            display: false
          }
        },
        title: {
          display: true,
          text: '日当たり平均の日次推移',
          fontSize: 30
        },
        scales: {
          yAxes: [{
              ticks: {
                  beginAtZero: true
              }
          }]
      }
      }
    return <Line data={data} options={options} width={1000} height={500}/> 
}