import React from "react"
import {Bar, Line, LineChart} from "react-chartjs-2"
//import 'chartjs-plugin-datalabels'

class MonthlyBenefit extends React.Component {

  render () {
    var datasets = []
    //length = 32
    var count = 0
    const colors = [
      '#d8b3ee', '#434d4b', '#301893', '#3cfdbb', '#10403f', '#8d0ab8', '#866ae3', '#6d7bfa', '#bc693f', '#d8c201',
      '#d8c201', '#79e23b', '#c7049c', '#631fff', '#801263', '#bd97a5', '#aed376', '#cb3f54', '#9c006a', '#ec337e',
      '#ec852c', '#1395a4', '#8d413c', '#ccf98c', '#3babc4', '#d1a87d', '#ec2d49', '#2e758b', '#74841c', '#6ece67',
      '#266c17', '#ae593f']
    colors.reverse()
    //monthly_benefits = [['id', 'monthly_benefits.first_name', 'monthly_benefits.last_name' 'target_date', 'today_sales'], ....]
    const monthly_benefits = this.props.monthly_benefits
    var i = 0
    var months = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
    var total_sales = Array(12).fill(null)
    var normal_sales = Array(12).fill(null)
    var upper_sales = Array(12).fill(null)
    var fixed_sales = Array(12).fill(null)

    for (i; i < monthly_benefits.length; i++) {
        const the_day = new Date(monthly_benefits[i].target_date)
        const index = the_day.getMonth()
        total_sales.splice(index, 1, monthly_benefits[i].total_sales)
        normal_sales.splice(index, 1, monthly_benefits[i].nomal_sales)
        upper_sales.splice(index, 1, monthly_benefits[i].upper_sales)
        fixed_sales.splice(index, 1, monthly_benefits[i].fixed_sales)
    }

    const total_dataset = {
        type:'line',
        data: total_sales,
        fill: false,
        borderColor: '#282846',
        label: '売上（合計）',
    }
    const normal_dataset = {
        type:'line',
        data: normal_sales,
        fill: false,
        borderColor: '#fed049',
        label: '売上（普通契約）',
    }
    const upper_dataset = {
        type:'line',
        borderColor: '#007580',
        data: upper_sales,
        fill: false,
        label: '売上（上限契約）',
    }
    const fixed_dataset = {
        type:'line',
        borderColor: '#d8ebe4',
        data: fixed_sales,
        fill: false,
        label: '売上（固定契約）',
    }
    const data = {
      labels: months,
      datasets: [total_dataset, normal_dataset, upper_dataset, fixed_dataset],
    };
    const options = {
      maintainAspectRatio: false,
      responsive: false,
      legend: { display: true, position: "bottom" },
      plugins: {
        datalabels: {
          display: false
        }
      },
      title: {
        display: true,
        text: '売上の月次推移',
        fontSize: 30
      },
    }
    return (
      <React.Fragment>
        <Line data={data} options={options} width={1000} height={500}/> 
      </React.Fragment>
    );
  }
}

export default MonthlyBenefit;
