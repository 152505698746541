import React, {useState} from 'react'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
//import { Link} from 'react-router-dom';
import {SidebarData} from './SidebarData';
//import '../packs/Navbar.css';
import {IconContext} from 'react-icons';

//import '../../assets/stylesheets/mypages.scss';

function Navbar(){
    const url = "https://neo-tokyo.work:8087/"
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

    return (
      <>
      <IconContext.Provider value={{ color: '#fff' }}>
      <div className='navbar'>
          <FaIcons.FaBars onClick={showSidebar} />
      </div>
      <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className="nav-menu-items" onClick={showSidebar}>
          <li className="navbar-toggle">
                
                <a href={url} className='menu-bars'>
                    <AiIcons.AiOutlineClose />
                </a>
            </li>
            {SidebarData.map((item, index)=> {return (
                <li key={index} className={item.cName}>
                <a href={url + item.path}>
                    {item.icon}
                    <span>{item.title}</span>    
                </a>
            </li>
                )
            })}
          </ul>
      </nav>
      </IconContext.Provider>
  </>
    )
}

export default Navbar;