import React from "react"
import {Bar, Line, LineChart} from "react-chartjs-2"
//import 'chartjs-plugin-datalabels'

class FixedUnitPriceBar extends React.Component {

    render () {
        const fixed_unit_price_counts = this.props.fixed_unit_price_counts
        var unit_prices = []
        var counts = []
        var i = 0
        for (i; i < fixed_unit_price_counts.length; i++) {
            unit_prices.push(fixed_unit_price_counts[i]['unit_price'])
            counts.push(fixed_unit_price_counts[i]['count'])
        }
        const dataset = {
            type:'bar',
            data: counts,
            label: '単価分布',
            backgroundColor: '#99ff66', // <--追加
        }
        const data = {
        labels: unit_prices,
        datasets: [dataset],
        };
        const options = {
        maintainAspectRatio: false,
        responsive: false,
        legend: { display: true, position: "bottom" },
        title: {
            display: true,
            text: '固定契約の単価分布',
            fontSize: 30
        },
        }
        return (
        <React.Fragment>
            <Bar data={data} options={options} width={1000} height={500}/> 
        </React.Fragment>
        );
  }
}

export default FixedUnitPriceBar;