import React from "react"
import {Bar, Line, LineChart} from "react-chartjs-2"
//import 'chartjs-plugin-datalabels'

class UppperLimitBar extends React.Component {

    render () {
        const upper_limit_counts = this.props.upper_limit_counts
        var upper_limits = []
        var counts = []
        var i = 0
        for (i; i < upper_limit_counts.length; i++) {
            upper_limits.push(upper_limit_counts[i]['upper_limit'])
            counts.push(upper_limit_counts[i]['count'])
        }
        const dataset = {
            type:'bar',
            data: counts,
            label: '上限分布',
            backgroundColor: '#ff66b3', // <--追加
        }
        const data = {
        labels: upper_limits,
        datasets: [dataset],
        };
        const options = {
        maintainAspectRatio: false,
        responsive: false,
        legend: { display: true, position: "bottom" },
        title: {
            display: true,
            text: '上限契約の上限分布',
            fontSize: 30
        },
        }
        return (
        <React.Fragment>
            <Bar data={data} options={options} width={1000} height={500}/> 
        </React.Fragment>
        );
  }
}

export default UppperLimitBar;