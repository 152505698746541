import React from "react"
import {Bar, Line, LineChart} from "react-chartjs-2"
//import 'chartjs-plugin-datalabels'

export default function DailyAverageProfitMonthlyTransit(props) {
  var months = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
  var days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  var total_sales = Array(12).fill(null)
  var normal_sales = Array(12).fill(null)
  var upper_sales = Array(12).fill(null)
  var fixed_sales = Array(12).fill(null)
  for (var i=0; i < props.monthly_benefits.length; i++) {
    const the_day = new Date(props.monthly_benefits[i].target_date)
    const index = the_day.getMonth()
    total_sales.splice(index, 1, props.monthly_benefits[i].total_sales/days[index])
    normal_sales.splice(index, 1, props.monthly_benefits[i].nomal_sales/days[index])
    upper_sales.splice(index, 1, props.monthly_benefits[i].upper_sales/days[index])
    fixed_sales.splice(index, 1, props.monthly_benefits[i].fixed_sales/days[index])
  }

  const total_dataset = {
      type:'line',
      data: total_sales,
      fill: false,
      borderColor: '#231E23',
      label: '売上（合計）',
  }
  const normal_dataset = {
      type:'line',
      data: normal_sales,
      fill: false,
      borderColor: '#39A6A3',
      label: '売上（普通契約）',
  }
  const upper_dataset = {
      type:'line',
      borderColor: '#DEEEEA',
      data: upper_sales,
      fill: false,
      label: '売上（上限契約）',
  }
  const fixed_dataset = {
      type:'line',
      borderColor: '#BF1363',
      data: fixed_sales,
      fill: false,
      label: '売上（固定契約）',
  }
  const data = {
    labels: months,
    datasets: [total_dataset, normal_dataset, upper_dataset, fixed_dataset],
  };
  const options = {
    maintainAspectRatio: false,
    responsive: false,
    legend: { display: true, position: "bottom" },
    plugins: {
      datalabels: {
        display: false
      }
    },
    title: {
      display: true,
      text: '日当たり売上の月次推移',
      fontSize: 30
    },
  }
  return  <Line data={data} options={options} width={1000} height={500}/> 
}
