import React from "react"
import { Doughnut } from "react-chartjs-2"
import 'chartjs-plugin-datalabels'

class DoughnutChart extends React.Component {

  render () {
    const chartdata = {
      labels:this.props.label,
      datasets:[{
        label: "売り上げの内訳",
        backgroundColor: [
          "#83ce83",
          "#959595",
          "#f96a5d",
          "#00A6B4",
          "#6800B4",
        ],
        data: this.props.data,
        },
      ],
    }
    const options = {
      legend: { display: true, position: "right" },
      plugins: {
        datalabels: {
          display: true,
          color: 'black'
        }},
      tooltips: {
        backgroundColor: "#5a6e7f",
      },
      maintainAspectRatio: false,
      responsive: false,
      title: {
        display: true,
        text: '売上金額内訳',
      },
    }

    return (
      <React.Fragment>
         <Doughnut data={chartdata} options={options} width={300} height={300}/>
      </React.Fragment>
    );
  }
}

export default DoughnutChart
