import React from "react"
import {Line} from 'react-chartjs-2'
//import 'chartjs-plugin-datalabels'


class StackAreaLine extends React.Component {
  render () {
    //const labels =this.props.data.map((x)=>  x.target_date); 
    const  labels = this.props.label;
    const data = this.props.data.map((x, index)=>(
      {
        label: this.props.title[index],
        fill: true,
        lineTension: 0.1,
        backgroundColor: this.props.backgroundColor[index],
        borderColor: this.props.borderColor[index],
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: x
      })

    );
    //const data = this.props.data.map((x)=>  x.total_view_num); 
    const graph = {
      labels: labels,
      datasets: data
    };
    const options = {
      scales: {
          yAxes: [{
              stacked: true
          }]
      },
      maintainAspectRatio: false,
      responsive: false,
      plugins: {
        datalabels: {
          display: true,
          color: 'black'
        }},
    };
    return (
      <React.Fragment>
        <Line data={graph} options={options} width={300} height={300}/>
      </React.Fragment>
    );
  }
}

export default StackAreaLine
