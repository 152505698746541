import React from "react"
import {Bar, Line, LineChart} from "react-chartjs-2"
//import 'chartjs-plugin-datalabels'

class MonthlyBenefit extends React.Component {

  render () {
    var datasets = []
    const today = new Date()
    //length = 32
    var count = 0
    const colors = [
      '#d8b3ee', '#434d4b', '#301893', '#3cfdbb', '#10403f', '#8d0ab8', '#866ae3', '#6d7bfa', '#bc693f', '#d8c201',
      '#d8c201', '#79e23b', '#c7049c', '#631fff', '#801263', '#bd97a5', '#aed376', '#cb3f54', '#9c006a', '#ec337e',
      '#ec852c', '#1395a4', '#8d413c', '#ccf98c', '#3babc4', '#d1a87d', '#ec2d49', '#2e758b', '#74841c', '#6ece67',
      '#266c17', '#ae593f']
    colors.reverse()
    //monthly_contracts = [['id', 'monthly_contracts.first_name', 'monthly_contracts.last_name' 'target_date', 'today_sales'], ....]
    const monthly_contracts = this.props.monthly_contracts
    var i = 0
    var months = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
    var datasets = []
    var contract_counts = Array(12).fill(null)
    var sums = Array(12).fill(null)
    contract_counts = contract_counts.map((_, index) => (index <= today.getMonth()?0:null))
    sums = sums.map((_, index) => (index <= today.getMonth()?0:null))

    for (i; i < monthly_contracts.length; i++) {
        const the_day = new Date(monthly_contracts[i].contract_start_date)
        const index = the_day.getMonth()
        contract_counts.splice(index, 1, monthly_contracts[i].count)
        sums.splice(index, 1, monthly_contracts[i].count + sums[index])

        if (i == monthly_contracts.length - 1 || monthly_contracts[i].id !== monthly_contracts[i+1].id) {
    
            var dataset = {
              type:'line',
              data: contract_counts,
              fill:false,
              label: monthly_contracts[i].last_name + ' ' + monthly_contracts[i].first_name,
              borderColor: colors[count],
            }
            count += 1
            contract_counts = Array(12).fill(0)
            contract_counts = contract_counts.map((_, index) => (index <= today.getMonth()?0:null))
            datasets.push(dataset)
          }
    }

    const total_dataset = {
        type:'line',
        data: sums,
        fill: false,
        borderColor: '#282846',
        label: '売上（合計）',
    }

    datasets.push(total_dataset)
    const data = {
      labels: months,
      datasets: datasets,
    };
    const options = {
      maintainAspectRatio: false,
      responsive: false,
      legend: { display: true, position: "bottom" },
      plugins: {
        datalabels: {
          display: false
        }
      },
      title: {
        display: true,
        text: '契約数の月次推移',
        fontSize: 30
      },
    }
    return (
      <React.Fragment>
        <Line data={data} options={options} width={1000} height={500}/> 
      </React.Fragment>
    );
  }
}

export default MonthlyBenefit;
