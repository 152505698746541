import React from "react"
import {Line} from 'react-chartjs-2';

class TransitionGraph extends React.Component {
  render () {
    //const labels =this.props.data.map((x)=>  x.target_date); 
    const  labels = this.props.label;
    const data = this.props.data;
    //const data = this.props.data.map((x)=>  x.total_view_num); 
    const graph = {
      labels: labels,
      datasets: [
        {
          label: labels,
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: data
        }
      ]
    };
    const options = {
      maintainAspectRatio: false,
      responsive: false,
      title: {
        display: true, // タイトルを表示する
        text: this.props.title_text, // タイトルのテキスト
      },
    };
    return (
      <React.Fragment>
        <Line data={graph} options={options} width={300} height={300}/>
      </React.Fragment>
    );
  }
}

export default TransitionGraph
