import React from "react"
import PropTypes from "prop-types"

import {Bar} from 'react-chartjs-2';

class Dashboard extends React.Component {
  render () {
    
    const data = {
      labels: ['通常契約', '上限契約', '固定契約'],
      datasets: [
        {
          data: [this.props.data.nomal_sales,this.props.data.upper_sales, this.props.data.fixed_sales],
          // 省略
        },
      ],
    };
    const options = {
      height:'200px',
      width:'200px',
    };
    return (
      <React.Fragment>
        <Bar data={data} options={options} /> 
      </React.Fragment>
    );
  }
}

export default Dashboard
