import React from 'react';
import { Bar } from 'react-chartjs-2';

const StackedBarChart = ({ labels, datasets }) => {
  const data = {
    labels: labels,
    datasets: datasets
  };

  const options = {
    scales: {
      xAxes: [{
        stacked: true
      }],
      yAxes: [{
        stacked: true
      }]
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      titleFontSize: 16, // タイトルの文字サイズ
      bodyFontSize: 20, // ボディの文字サイズ
      intersect: false,
      callbacks: {
        label: function(tooltipItem, data) {
          // データポイントの値を取得
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return '値: ' + value; // ツールチップ内に表示するテキスト
        }
      }
    },
  };

  return (
    <div className="chart-container">
  <Bar data={data} options={options}/>
  </div>
  );
};

export default StackedBarChart